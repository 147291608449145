<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">
      <back back-url="/question"></back>
      <scroll
        ref="scroll"
        class="scroll"
      >
        <div>
          <div class="desc">
            <div class="inp">
              <field
                v-model="msg"
                rows="10"
                type="textarea"
                maxlength="500"
                placeholder="问题描述(请尽量详细，以便快速定位问题)"
                show-word-limit
              />
            </div>
            <div class="pic">
              <div class="pic-list">
                <div class="img-wrapper" v-for="(item,index) in medias" :key="index">
                  <img
                    class="img"
                    :src="item.media_file"
                    alt=""
                    @click="previewImage(item.media_file)"
                  />
                  <div class="img-delete" @click="deleteImg(index)">
                    <icon name="cross" class="delete-icon"/>
                  </div>
                </div>
                <div class="uploader" @click="uploader" v-show="medias.length<8"></div>
              </div>
              <div class="text">添加图片（选填，请提供问题截图）</div>
            </div>
          </div>
          <div class="btn" @click="handleClick">提交</div>
        </div>
      </scroll>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import Back from '@/components/back/Back'
import { Field, Icon, Toast } from 'vant'
import wx from 'weixin-js-sdk'
import { wxUpload } from '@/api/Uploader'
import { mapGetters } from 'vuex'
import { createMsg } from '@/api/Msg'

export default {
  name: 'Question',
  components: {
    Back,
    Scroll,
    Field,
    Icon
  },
  data () {
    return {
      msg: '',
      medias: [],
      editinfo: false
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid'
    ])
  },
  created () {
    this._getCUserInfoNew()
  },
  methods: {
    async uploader () {
      const { localIds } = await this.chooseImg()
      let mediaLen = localIds.length + this.medias.length
      if (mediaLen > 8) {
        return Toast.fail('最多只允许上传8张图片')
      }
      for (const item of localIds) {
        await this.uploadImg(item).then(res => {
          const { code, data } = res
          if (code === 200) {
            this.medias.push(data)
          }
        })
      }
    },
    chooseImg () {
      return new Promise((resolve) => {
        wx.chooseImage({
          count: 8,
          success: (res) => {
            resolve(res)
          }
        })
      })
    },
    uploadImg (localId) {
      return new Promise((resolve) => {
        wx.uploadImage({
          localId: localId,
          isShowProgressTips: 1,
          success: (res) => {
            const uploader = wxUpload({
              server_id: res.serverId,
              media_type: 1,
              c_user_guid: this.userGuid
            })
            resolve(uploader)
          }
        })
      })
    },
    deleteImg (index) {
      this.medias.splice(index, 1)
    },
    previewImage (current) {
      let urls = []
      this.medias.forEach((item) => {
        urls.push(item.media_file)
      })
      wx.previewImage({
        current: current,
        urls: urls
      })
    },
    handleClick () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let medias = []
      this.medias.forEach(item => {
        medias.push(item.media_guid)
      })
      createMsg({
        msg_agency: this.agencyGuid,
        c_user_guid: this.userGuid,
        msg_parent: '-',
        msg_to_obj: this.agencyGuid,
        msg_type: 1,
        msg_desc: this.msg,
        msg_medias: medias
      }).then(res => {
        console.log(res)
        const { code, msg } = res
        if (code === 200) {
          this.msg = ''
          this.medias.splice(0, this.medias.length)
          Toast.success(msg)
        } else {
          Toast.fail(msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 80px
    left 0
    right 0
    bottom 0
    overflow hidden

.desc
  width 690px
  margin 30px auto
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.02)
  border-radius 10px
  background rgba(255, 255, 255, 1)

  .inp
    width 100%

  .pic
    display flex
    flex-wrap wrap
    justify-content center
    align-items center
    width 650px
    padding-bottom 30px
    margin 0 auto
    border-top 2px solid rgba(215, 213, 213, 1)

    .pic-list
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      width 100%

      .img-wrapper
        position relative
        width 100px
        height 100px
        margin 20px
        overflow hidden

        .img
          width 100%
          height 100%

        .img-delete
          position absolute
          top 0
          right 0
          width 30px
          height 30px
          border-radius 0 0 0 30px
          background-color rgba(0, 0, 0, 0.7)

          .delete-icon
            position absolute
            top 4px
            right 4px
            font-size 16px
            color rgba(255, 255, 255, 1)

      .uploader
        width 80px
        height 68px
        margin 20px
        bg-image('~@/assets/img/question/upload')
        background-size 100% 100%
        background-repeat no-repeat

    .text
      width 100%
      text-align center
      font-size 24px
      color rgba(136, 136, 136, 1)

.btn
  display flex
  align-items center
  justify-content center
  width 460px
  height 60px
  margin 0 auto
  line-height normal
  font-size 24px
  color rgba(255, 255, 255, 1)
  border-radius 30px
  background rgba(179, 53, 58, 1)
</style>
